/**
 * @author DCHIRUM1
 */
import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {RfiRequestService} from '../../services/rfi/rfi-request.service';
import {RfiCostRequestService} from '../../services/rfi/rfi-cost-request.service';
import {RfiThresholdRequestService} from '../../services/rfi/rfi-threshold-request.service';
import {PartialRfiRequestService} from '../../services/rfi/partial-rfi-request.service';
import {PartialRfiDraftRequestService} from 'src/app/services/rfi/partial-rfi-draft-request.service';
import moment from 'moment/moment';
import {ManualRfiRequestService} from 'src/app/services/rfi/manual-rfi-request.service';

@Component({
	selector: 'app-create-partial-rfis',
	templateUrl: './create-partial-rfis.component.html',
	styleUrls: ['./create-partial-rfis.component.css'],
	providers: [MessageService, ConfirmationService]
})
export class CreatePartialRFIsComponent implements OnInit {
	cmfrDCRList = [];
	cmfrDCRMap;
	cmfrDCR;
	partNumberList = [];
	partNumberMap;
	partNumber;
	plantCodeList = [];
	plantCode;
	plantCode4Digit;
	plantCode5Digit;
	psaList = [];
	psa1;
	psa;
	partPrefix;
	partBase;
	partSuffix;
	materialCosts = [];
	commercialCosts = [];
	totPrevRFI = 0;
	totCurvRFI = 0;
	rfiList = [];
	gsdbList = [];
	rfiId;
	adsRequest;
	pdSystemData;
	cmDCRStartWithD = false;
	activeIndex = 0;
	id;
	isPdsDataAvailable = true;
	validationMessage: any;
	isCostEstimator: boolean;
	isBuyer: boolean;
	isPsaBuyer: boolean;
	isValidGsdbCode = true;
	isValidBuyer = false;
	isValidCostEstimator = false;
	isValidSecondaryEngineer = true;
	validationSecondaryEngineerMessage: any;
	validationMessageBuyer: any;
	isRFICopied = false;
	isDRCopied = false;
	isCopiedSupplier = false;
	isCopiedSecondarySupplier = false;
	isCopiedCostEstimator = false;
	psaBuyer = '';
	isDuplicateDraftPartialRFI = false;
	isSupplierEmailValid = true;
	isSecondarySupplierEmailValid = true;
	isSubmit;
	private costEstimatorMap = {};
	breadCrumItems: MenuItem[];
	interanlCostDetailsEdit = false;
	showInteranlCostDetailsEdit = true;
	private mode: string;
	private buyerSupplier: string;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private rfiService: RfiRequestService,
		private messageService: MessageService,
		private partialRfiRequestService: PartialRfiRequestService,
		private rfiCostRequestService: RfiCostRequestService,
		private rfiRequestService: RfiRequestService,
		private rfiThresholdRequestService: RfiThresholdRequestService,
		private confirmationService: ConfirmationService,
		private partialRfiDraftService: PartialRfiDraftRequestService,
		private manualRfiRequestService: ManualRfiRequestService
	) {}

	ngOnInit() {
		this.adsRequest = {
			psa: '',
			supplierGSDBCode: '',
			supplier: '',
			supplierEnggEmail: '',
			buyerCode: '',
			purCommCode: '',
			purCommDesc: '',
			costEstimator: '',
			leadPrg: '',
			currency: ''
		};

		this.pdSystemData = {
			prefix: '',
			base: '',
			suffix: '',
			partName: '',
			mplPlatCode: '',
			fedeBomCode: '',
			cmfDCR: '',
			cmfDCRTitle: '',
			cmfDCRLink: '',
			drCDSID: '',
			cmfDCRChangeDesc: '',
			postFDJ: '',
			initialPiecePrice: '',
			initialPiecePriceCurr: '',
			piecePriceStatus: '',
			piecePriceStatusCurr: '',
			piecePriceEstimate: '',
			piecePriceEstimateCurr: '',
			initialProdToolTar: '',
			initialProdToolTarCurr: '',
			prodToolStatus: '',
			prodToolStatusCurr: '',
			prodToolEstim: '',
			prodToolEstimCurr: ''
		};

		this.retriveRouteParameter();

		this.breadCrumItems = [
			{
				label: 'Buyer Home Page',
				url: '#/buyer-supplier/buyer',
				target: '_self'
			},
			{
				label: 'Partial RFI/RFQ',
				url: '#/partial-draft-rfi-request',
				target: '_self'
			},
			{
				label:
					this.id && this.id !== 'null'
						? 'Edit Partial RFI/RFQ'
						: 'Create Partial RFI/RFQ',
				target: '_self'
			}
		];
	}

	isAdminRole(apsRoles) {
		return (
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('PD_Engineer') ||
			apsRoles.includes('eDCM_Super_Buyer') ||
			apsRoles.includes('Buyer') ||
			apsRoles.includes('JV Engineer') ||
			apsRoles.includes('JV Buyer')
		);
	}

	isSupplierEmailIdValid(email) {
		const val = this.validateEmail(email);
		if (!val) {
			this.isSupplierEmailValid = false;
		} else {
			this.isSupplierEmailValid = true;
		}
	}
	isSecondarySupplierEmailIdValid(email) {
		const val = this.validateEmail(email);
		if (!val) {
			this.isSecondarySupplierEmailValid = false;
		} else {
			this.isSecondarySupplierEmailValid = true;
		}
	}

	validateEmail(email) {
		const regexpEmail = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+.com'); // NOSONAR
		if (email) {
			email = email.toLowerCase();
		}
		return regexpEmail.test(email);
	}

	validateSuplierEmail() {
		const regexpEmail = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+.com'); // NOSONAR
		if (this.adsRequest.supplier) {
			const email = this.adsRequest.supplier.toLowerCase();
			if (regexpEmail.test(email) === true) {
				this.isCopiedSupplier = true;
			}
		}
	}
	validateSecondarySuplierEmail() {
		const regexpEmail = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+.com'); // NOSONAR
		if (this.adsRequest.supplierEnggEmail) {
			const email = this.adsRequest.supplierEnggEmail.toLowerCase();
			if (regexpEmail.test(email) === true) {
				this.isCopiedSecondarySupplier = true;
			}
		}
	}

	validateBuyerCode(code, length) {
		return this.validateCode(code, length);
	}

	validateCostEstimator(code, length) {
		// tslint:disable-next-line:indent
		//this.validationMessage = 'Please enter a valid Cdsid'
		return !code || this.validateCode(code, length);
	}

	validateSiteCode(code, length) {
		return this.validateCode(code, length);
	}

	validatePsa(code, length) {
		return this.validateCode(code, length);
	}

	validateCommodity(code, length) {
		return this.validateCode(code, length);
	}

	validateCode(code, length) {
		const regexpCode = new RegExp('^[A-Za-z0-9]*$');
		if (code) {
			code = code.trim();
		}

		return code && code.length <= length && regexpCode.test(code);
	}

	ongsdbSelect() {
		if (this.adsRequest.siteCode1 && this.adsRequest.siteCode1.value) {
			this.adsRequest.siteCode = this.adsRequest.siteCode1.value;
		} else {
			this.adsRequest.siteCode = null;
		}
	}

	onInputBlur() {
		console.log('Input blurred:', this.adsRequest.psa);
		if (!this.rfiId) {
			if (!this.adsRequest.psa) {
				this.isPdsDataAvailable = true;
			}
			this.isCopiedSecondarySupplier = false;
			this.isCopiedSupplier = false;
			this.isCopiedCostEstimator = false;

			this.rfiRequestService
				.findRfiForPsa(this.adsRequest.psa)
				.subscribe((response) => {
					console.log(response);
					if (
						response &&
						response.constructor === Object &&
						Object.keys(response).length !== 0
					) {
						//this.adsRequest.siteCode = response.supplier ? response.supplier.trim() : '';
						this.adsRequest.supplier = response.supplierContact
							? response.supplierContact.trim()
							: '';
						this.adsRequest.resCur = response.respCur
							? response.respCur.trim()
							: '';
						this.adsRequest.leadPrg = response.leadProgram
							? response.leadProgram.trim()
							: '';
						this.adsRequest.buyerCode = response.buyerCode
							? response.buyerCode.trim()
							: '';
						this.psaBuyer = this.adsRequest.buyerCode;
						this.adsRequest.secondaryEngineer =
							response.secondaryEngineer
								? response.secondaryEngineer.trim()
								: '';

						this.adsRequest.siteCode = response.supplier
							? response.supplier.trim()
							: '';

						//this.adsRequest.psa = response.psa ? response.psa.trim() : '';
						this.adsRequest.supplierEnggEmail =
							response.suppEnggEmail
								? response.suppEnggEmail.trim()
								: '';
						this.adsRequest.purCommCode = response.commodity
							? response.commodity.trim()
							: '';
						this.adsRequest.purCommDesc = response.materialGroup
							? response.materialGroup.trim()
							: '';
						this.adsRequest.costEstimator = response.CostEstimator
							? response.CostEstimator.trim()
							: '';
						if (response.respCur && response.respCur.length === 3) {
							this.isPdsDataAvailable = true;
						}
						this.isRFICopied = true;
						this.validateBuyer();
						this.validateCheck(true);
						this.validateSecondaryEngineer(true);
						this.validateSuplierEmail();
						this.validateSecondarySuplierEmail();
					} else {
						this.adsRequest.siteCode = '';
						this.adsRequest.supplier = '';
						this.adsRequest.resCur = '';
						this.adsRequest.leadPrg = '';
						this.adsRequest.buyerCode = '';

						this.adsRequest.supplierEnggEmail = '';
						this.adsRequest.purCommCode = '';
						this.adsRequest.purCommDesc = '';
						this.adsRequest.costEstimator = '';
						this.adsRequest.secondaryEngineer = null;
						this.isRFICopied = false;
						if (
							this.adsRequest.psa &&
							this.adsRequest.psa.length > 0
						) {
							this.isPdsDataAvailable = false;
						}
						this.validateBuyer();
						this.validateCheck(true);
					}
					this.rfiRequestService
						.fetchGsdbList(this.adsRequest.psa)
						// eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
						.subscribe((response) => {
							this.gsdbList = response;
							//this.adsRequest.siteCode1 = this.gsdbList.find(i => i.value === this.adsRequest.siteCode.trim());
							this.adsRequest.siteCode = null;
						});
				});
		}
	}

	populateCosts(costs, key, value) {
		const costMap = {
			costBucket: key,
			prevRFI: value
		};

		costs.push(costMap);
	}

	getPartialRFIList() {
		this.router.navigate(['partial-draft-rfi-request']);
	}

	loadPartialRFIDraft(response) {
		this.adsRequest.leadPrg = response.program;
		this.adsRequest.psa = response.psaNumber;
		this.adsRequest.resCur = response.currency;
		this.plantCode5Digit = response.plant;
		this.plantCode4Digit = response.fourDigitPlantNo;

		this.materialCosts = [];
		this.populateCosts(
			this.materialCosts,
			'Total Raw Material Cost',
			response.totalRawMaterial
		);
		this.populateCosts(
			this.materialCosts,
			'Total Purchased Parts / Bought-Out Content',
			response.boughtOutContent
		);
		this.populateCosts(
			this.materialCosts,
			'Total Labor',
			response.totalLabor
		);
		this.populateCosts(
			this.materialCosts,
			'Total Burden',
			response.totalBurden
		);
		this.populateCosts(
			this.materialCosts,
			'End Item Scrap',
			response.endItemScrap
		);
		this.populateCosts(this.materialCosts, 'SG&A', response.sga);
		this.populateCosts(this.materialCosts, 'Profit', response.profit);
		this.populateCosts(
			this.materialCosts,
			'Total Purchased Parts / Bought-Out Mark-Ups',
			response.boughtOutMarkups
		);
		this.populateCosts(
			this.materialCosts,
			'Piece Price Subtotal',
			this.get4Decimal(response.grossPrice)
		);

		this.commercialCosts = [];
		this.populateCosts(
			this.commercialCosts,
			'ED&T paid in Piece Price',
			response.edt
		);
		this.populateCosts(
			this.commercialCosts,
			'Packaging',
			response.packaging
		);
		this.populateCosts(this.commercialCosts, 'ILVS', response.ilvs);
		this.populateCosts(
			this.commercialCosts,
			'Freight Value',
			response.freight
		);
		this.populateCosts(this.commercialCosts, 'Duty', response.duty);
		this.populateCosts(
			this.commercialCosts,
			'Other (Miscellaneous)',
			response.other
		);
		this.populateCosts(
			this.commercialCosts,
			'Commercial Adjustment Total',
			this.get4Decimal(response.netPrice)
		);
		this.adsRequest.siteCode = response.manufacturingSiteCode;
		//this.partPrefix + '-' + this.partBase + '-' + this.partSuffix = response.materialNumber;
		if (response.materialNumber.split('-').length === 3) {
			this.partPrefix = response.materialNumber.split('-')[0];
			this.partBase = response.materialNumber.split('-')[1];
			this.partSuffix = response.materialNumber.split('-')[2];
		}
		this.adsRequest.secondaryEngineer = response.secondaryEngineer;
		this.adsRequest.supplier = response.supplierUniqueName;
		this.adsRequest.toolingCostTarget = response.edtToolingTotal;
		this.adsRequest.buyerCode =
			response.buyerCdsId != null ? response.buyerCdsId.trim() : '';
		this.adsRequest.purCommCode =
			response.commodityCode != null ? response.commodityCode.trim() : '';
		this.adsRequest.purCommDesc = response.commodityName;
		//programReceived:,
		this.adsRequest.costEstimator = response.costEstimator;
		this.adsRequest.supplierEnggEmail =
			response.supplierEngineerName != null
				? response.supplierEngineerName
				: response.secondSupplier;
		this.adsRequest.prevSuffix = response.prevSuffix;
		this.pdSystemData.leadPrg = response.program;
		this.pdSystemData.prefix = response.partPrefix;
		this.pdSystemData.base = response.partBase;
		this.pdSystemData.suffix = response.partSuffix;
		this.pdSystemData.partName = response.partName;
		this.pdSystemData.cmfDCRChangeDesc = response.requestDescription;
		this.pdSystemData.drCDSID = response.drCdsId;
		this.pdSystemData.mplPlatCode = response.plant;
		this.pdSystemData.postFDJ = response.postFdj;
		this.pdSystemData.cmfDCRLink = response.engineeringDetails;
		if (response.materialCostStatus) {
			this.pdSystemData.piecePriceStatus = response.materialCostStatus;
		}
		if (response.toolingCostStatus) {
			this.pdSystemData.prodToolStatus = response.toolingCostStatus;
		}
		if (response.materialCostEngineeringEstimate) {
			this.pdSystemData.piecePriceEstimate =
				response.materialCostEngineeringEstimate;
		}
		if (response.toolingCostEngineeringEstimate) {
			this.pdSystemData.prodToolEstim =
				response.toolingCostEngineeringEstimate;
		}
		if (response.partCostTarget) {
			this.pdSystemData.initialPiecePrice = response.partCostTarget;
		}
		if (response.toolingCostTarget) {
			this.pdSystemData.initialProdToolTar = response.toolingCostTarget;
		}
		this.pdSystemData.piecePriceStatusCurr =
			response.materialCostStatusCurrency;
		this.pdSystemData.prodToolStatusCurr =
			response.toolingCostStatusCurrency;
		this.pdSystemData.piecePriceEstimateCurr =
			response.materialCostEngineeringEstimateCurrency;
		this.pdSystemData.prodToolEstimCurr =
			response.toolingCostEngineeringEstimateCurrency;
		this.pdSystemData.initialPiecePriceCurr =
			response.partCostTargetCurrency;
		this.pdSystemData.initialProdToolTarCurr =
			response.toolingCostTargetCurrency;
		this.pdSystemData.fedeBomCode = response.fourDigitPlantNo;
		this.pdSystemData.psa = response.psaNumber;
		this.pdSystemData.cmfDCRTitle = response.requestTitle;
		this.pdSystemData.cmfDCR = response.requestNo;
		this.validateBuyer();
		this.validateCheck(true);
		this.rfiRequestService
			.findRfiForPsa(this.adsRequest.psa)
			// eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
			.subscribe((response) => {
				console.log(response);
				if (
					response &&
					response.constructor === Object &&
					Object.keys(response).length !== 0
				) {
					this.isPdsDataAvailable = true;
				} else {
					this.isPdsDataAvailable = false;
				}
			});

		this.rfiRequestService
			.fetchGsdbList(this.adsRequest.psa)
			// eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
			.subscribe((response) => {
				this.gsdbList = response;
				this.adsRequest.siteCode1 = this.gsdbList.find(
					(i) => i.value === this.adsRequest.siteCode.trim()
				);
				if (
					this.gsdbList.length === 0 &&
					this.adsRequest.siteCode != null
				) {
					this.adsRequest.siteCode = this.adsRequest.siteCode.trim();
				}
			});
	}

	onCMFDCRSelect(draftResponse) {
		let userId = sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (this.isAdminRole(apsRoles)) {
			userId = 'eDCM_IT_ADMIN';
		}
		this.psaList = [];

		if (this.cmfrDCR && this.cmfrDCR.label) {
			this.cmDCRStartWithD = this.cmfrDCR.label.startsWith('D-');
			this.partNumberMap = this.cmfrDCRMap[this.cmfrDCR.label];
			this.partNumberList = [];
			this.plantCodeList = [];

			this.rfiThresholdRequestService
				.getPSAListByCMFR(userId, this.cmfrDCR.label)
				.subscribe((response) => {
					this.psaList = response;
					this.onPartNumberSelect(draftResponse);
					this.onPlantCodeSelect(draftResponse);
					if (
						draftResponse &&
						draftResponse.copyPsaNumber &&
						draftResponse.copyRFI
					) {
						this.onPsaSelect(draftResponse);
					}
				});

			// eslint-disable-next-line guard-for-in
			for (const partNumber in this.partNumberMap) {
				this.partNumberList.push({
					label: partNumber,
					value: partNumber
				});
			}
		} else {
			this.partNumberList = [];
			this.plantCodeList = [];
			this.pdSystemData = {};
			this.partPrefix = null;
			this.partBase = null;
			this.partSuffix = null;
			this.plantCode5Digit = null;
			this.plantCode4Digit = null;
			this.partNumber = null;
			this.plantCode = null;
			this.psa1 = null;
			this.rfiId = null;
		}
	}

	isParentDataSelected() {
		return this.cmfrDCR && this.partNumber && this.plantCode;
	}

	onPartNumberSelect(draftResponse) {
		//for (const partNumber in this.partNumberMap) {
		this.plantCodeList = [];

		if (this.id && this.id !== 'null' && draftResponse) {
			const partNum = draftResponse.materialNumber.replace(/\s+/g, '');
			this.partNumber = {label: partNum, value: partNum};
		}

		if (this.partNumber) {
			const plantCodes = this.partNumberMap[this.partNumber.label];
			for (const plantCode of plantCodes) {
				const plat5DigitCode = plantCode.split(',')[0];
				const plat4DigitCode = plantCode.split(',')[1];
				this.plantCodeList.push({
					label: plat5DigitCode.trim(),
					plat5DigitCode: plat5DigitCode.trim(),
					plat4DigitCode: plat4DigitCode
						? plat4DigitCode.trim()
						: 'null'
				});
			}
		}

		//}

		this.partPrefix = null;
		this.partBase = null;
		this.partSuffix = null;

		if (this.partNumber) {
			this.partPrefix = this.partNumber.label.split('-')[0];
			this.partBase = this.partNumber.label.split('-')[1];
			this.partSuffix = this.partNumber.label.split('-')[2];
		} else {
			this.plantCodeList = [];
			this.pdSystemData = {};
			this.partPrefix = null;
			this.partBase = null;
			this.partSuffix = null;
			this.plantCode5Digit = null;
			this.plantCode4Digit = null;
			this.plantCode = null;
			this.psa1 = null;
			this.rfiId = null;
		}
	}

	onPlantCodeSelect(draftResponse) {
		this.plantCode5Digit = null;
		this.plantCode4Digit = null;

		this.checkPartialRFIDraftAlreadyExist();

		if (this.id && this.id !== 'null' && draftResponse) {
			this.plantCode = {
				label: draftResponse.plant ? draftResponse.plant.trim() : '',
				plat5DigitCode: draftResponse.plant
					? draftResponse.plant.trim()
					: '',
				plat4DigitCode: draftResponse.fourDigitPlantNo
					? draftResponse.fourDigitPlantNo.trim()
					: 'null'
			};
		}

		if (this.plantCode) {
			this.plantCode5Digit = this.plantCode.plat5DigitCode;
			this.plantCode4Digit =
				this.plantCode.plat4DigitCode !== 'null'
					? this.plantCode.plat4DigitCode
					: 'N/A';

			this.retrivePDSystemData();
		} else {
			this.pdSystemData = {};
			this.plantCode5Digit = null;
			this.plantCode4Digit = null;
			this.psa1 = null;
			this.rfiId = null;
		}
	}

	onPsaSelect(draftResponse) {
		if (this.id && this.id !== 'null' && draftResponse) {
			this.psa1 = {value: Number(draftResponse.copyPsaNumber)};
		}

		if (this.psa1 && this.psa1.value) {
			this.psa = this.psa1.value;
			this.fetchRFIHeaderData(this.psa, draftResponse);
			this.rfiRequestService
				.fetchGsdbList(this.psa)
				.subscribe((response) => {
					this.gsdbList = response;
				});
		} else {
			this.rfiList = [];
			this.adsRequest = {};
			this.psa1 = null;
			this.rfiId = null;
		}
	}

	get4Decimal(prevRFI) {
		return (Math.sign(prevRFI) * Math.abs(prevRFI)).toFixed(4);
	}

	fetchRFIHeaderData(psa, draftResponse) {
		this.rfiRequestService.findRfiListForPsa(psa).subscribe((response) => {
			this.rfiList = response;
			if (response) {
				this.isPdsDataAvailable = true;
			}
			this.onRFISelect(draftResponse);
		});
		// this.rfiRequestService.findById(this.rfiId).subscribe(response => {
		// 	this.rfiRequest = response;
		// });
	}

	onRFISelect(draftResponse) {
		if (this.id && this.id !== 'null' && draftResponse) {
			const label = draftResponse.copyRFI;
			if (label.includes('/')) {
				const value = label.split('/')[0];
				this.rfiId = {label, value};
			}
		} else {
			if (this.rfiId) {
				this.rfiRequestService
					.findById(this.rfiId.value)
					.subscribe((response) => {
						this.adsRequest = response;
						this.adsRequest.supplierEnggEmail =
							response.secondSupplier;
						this.adsRequest.secondaryEngineer =
							response.secondaryEngineer;
						this.isRFICopied = true;
						if (this.adsRequest.costEstimator) {
							this.adsRequest.costEstimator =
								this.adsRequest.costEstimator.trim();
						}
						this.adsRequest.siteCode1 = this.gsdbList.find(
							(i) => i.value === this.adsRequest.siteCode.trim()
						);
						this.retriveAllCosts(null);
						this.validateBuyer();
						this.validateCheck(true);
					});
			} else {
				this.adsRequest = {};
			}
		}
	}

	currencyDisable() {
		if (
			(this.isRFICopied &&
				this.adsRequest.resCur &&
				this.adsRequest.resCur.length === 3) ||
			(this.adsRequest.psa &&
				this.isPdsDataAvailable &&
				this.adsRequest.resCur &&
				this.adsRequest.resCur.length === 3 &&
				(this.adsRequest.id === 'null' ||
					this.adsRequest.id !== 'null'))
		) {
			return true;
		}
		return false;
	}

	inputDisable(input) {
		if (
			this.adsRequest.psa &&
			this.isPdsDataAvailable &&
			input &&
			(this.adsRequest.id === 'null' || this.adsRequest.id !== 'null')
		) {
			return true;
		}
		return false;
	}

	supplierInputDisable(input) {
		if (
			this.adsRequest.psa &&
			this.isPdsDataAvailable &&
			this.validateEmail(this.adsRequest.supplier) &&
			this.isCopiedSupplier &&
			input &&
			(this.adsRequest.id === 'null' || this.adsRequest.id !== 'null')
		) {
			return true;
		}
		return false;
	}

	costEstimatorInputDisable(input) {
		if (
			this.adsRequest.psa &&
			this.isPdsDataAvailable &&
			!this.isCostEstimator &&
			this.isCopiedCostEstimator &&
			input &&
			(this.adsRequest.id === 'null' || this.adsRequest.id !== 'null')
		) {
			return true;
		}
		return false;
	}

	secondarySupplierInputDisable(input) {
		if (
			this.adsRequest.psa &&
			this.isPdsDataAvailable &&
			this.validateEmail(this.adsRequest.supplierEnggEmail) &&
			this.isCopiedSecondarySupplier &&
			input &&
			(this.adsRequest.id === 'null' || this.adsRequest.id !== 'null')
		) {
			return true;
		}
		return false;
	}

	inputSecondaryEngineerDisable(input) {
		if (
			this.adsRequest.psa &&
			this.isPdsDataAvailable &&
			this.isDRCopied &&
			input &&
			this.isValidSecondaryEngineer &&
			(this.validationSecondaryEngineerMessage === undefined ||
				!this.validationSecondaryEngineerMessage) &&
			(this.adsRequest.id === 'null' || this.adsRequest.id !== 'null')
		) {
			return true;
		}
		return false;
	}

	filterMaterialCost(costName) {
		const matCosts = this.materialCosts.filter(
			(rowData) => rowData.costBucket === costName
		);
		let materialCost = '';
		if (matCosts && matCosts[0]) {
			materialCost = matCosts[0].prevRFI;
		}
		return materialCost;
	}

	filterCommercialCost(costName) {
		const commCost = this.commercialCosts.filter(
			(rowData) => rowData.costBucket === costName
		);
		let commercialCost = '';
		if (commCost && commCost[0]) {
			commercialCost = commCost[0].prevRFI;
		}
		return commercialCost;
	}

	saveADSData() {
		this.activeIndex = 1;
	}

	isMandatoryFields() {
		if (this.adsRequest.siteCode1 && !this.adsRequest.siteCode) {
			this.adsRequest.siteCode = this.adsRequest.siteCode1.value;
		}
		return (
			this.adsRequest.psa &&
			this.adsRequest.siteCode &&
			this.validateSiteCode(this.adsRequest.siteCode, 5) &&
			this.adsRequest.supplier &&
			this.validateEmail(this.adsRequest.supplier) &&
			this.adsRequest.buyerCode &&
			this.validateBuyerCode(this.adsRequest.buyerCode, 8) &&
			this.validateCostEstimator(this.adsRequest.costEstimator, 8) &&
			this.adsRequest.leadPrg &&
			this.adsRequest.resCur
		);
	}

	isMandatoryField() {
		if (this.adsRequest.siteCode1 && !this.adsRequest.siteCode) {
			this.adsRequest.siteCode = this.adsRequest.siteCode1.value;
		}

		const mandatory =
			this.adsRequest.psa &&
			this.adsRequest.supplier &&
			this.adsRequest.buyerCode &&
			this.adsRequest.resCur &&
			this.adsRequest.leadPrg &&
			this.adsRequest.siteCode &&
			this.adsRequest.purCommCode &&
			this.adsRequest.purCommDesc;
		this.isSubmit = !mandatory;

		return mandatory;
	}

	savePdSystemData() {
		if (!this.isMandatoryField()) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Mandatory fields are required.'
				});
			}, 30);
			return;
		}
		if (this.isValidBuyer || this.isValidCostEstimator) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Warning',
					detail: 'Fix the error before submitting.'
				});
			}, 30);
		} else {
			if (this.isPsaBuyer) {
				this.confirmationService.confirm({
					message:
						'Buyer CDSID does not match existing PSA buyer. Would you like to update for this PSA? Update will take effect upon RFI Creation.',
					header: 'Confirmation',
					icon: 'pi pi-exclamation-triangle',
					key: 'buyerConfirmation',
					acceptLabel: 'Accept',
					rejectLabel: 'Discard',
					accept: () => {
						this.confirmationService.confirm({
							message:
								'Are you sure you want to "Submit" Partial RFI Data',
							header: 'Confirmation',
							icon: 'pi pi-exclamation-triangle',
							accept: () => {
								this.savePartialRFI();
							},
							reject: () => {
								// do nothing
							}
						});
					},
					reject: () => {
						this.adsRequest.buyerCode = this.psaBuyer;
						this.isPsaBuyer = false;
					}
				});
			} else {
				this.confirmationService.confirm({
					message:
						'Are you sure you want to "Submit" Partial RFI Data',
					header: 'Confirmation',
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
						this.savePartialRFI();
					},
					reject: () => {
						//do nothing
					}
				});
			}
		}
	}

	saveAsDraft() {
		const pdSystemData = this.getPDSystemData();
		const adsData = this.getADSData();
		if (this.psa1 && this.psa1.value) {
			adsData['copyPsaNumber'] = this.psa1.value.toString();
		}

		if (this.rfiId && this.rfiId.label) {
			adsData['copyRFI'] = this.rfiId.label;
		}

		if (!pdSystemData.materialCostEngineeringEstimate) {
			delete pdSystemData.materialCostEngineeringEstimate;
		}

		if (!pdSystemData.toolingCostEngineeringEstimate) {
			delete pdSystemData.toolingCostEngineeringEstimate;
		}

		if (!pdSystemData.toolingCostEngineeringEstimate) {
			delete pdSystemData.toolingCostEngineeringEstimate;
		}

		if (!pdSystemData.partCostTarget) {
			delete pdSystemData.partCostTarget;
		}

		if (!pdSystemData.toolingCostTarget) {
			delete pdSystemData.toolingCostTarget;
		}

		if (!pdSystemData.materialCostStatus) {
			delete pdSystemData.materialCostStatus;
		}

		if (this.gsdbList.length > 0 && !this.adsRequest.siteCode1) {
			delete adsData.manufacturingSiteCode;
		}

		// eslint-disable-next-line guard-for-in
		for (const key in adsData) {
			pdSystemData[key] = adsData[key];
		}

		pdSystemData['id'] = parseInt(this.id, 10);
		this.partialRfiDraftService
			.saveDraft(pdSystemData)
			.subscribe((response) => {
				this.id = response.id;
				setTimeout(() => {
					this.messageService.add({
						life: 300000,
						severity: 'success',
						summary: 'Success',
						detail: 'The partial RFI/RFQ draft has been saved successfully. Click the Back button to check the draft.'
					});
				}, 30);
			});
	}

	close() {
		this.router.navigate(['/partial-draft-rfi-request']);
	}

	cancel() {
		this.confirmationService.confirm({
			message: 'Are you sure you want to "Reset" RFI data',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.cmfrDCR = null;
				this.psa1 = null;
				this.cmfrDCRList = [];
				this.partNumberList = [];
				this.plantCodeList = [];
				this.psaList = [];
				this.materialCosts = [];
				this.commercialCosts = [];
				this.rfiList = [];
				this.partPrefix = null;
				this.partBase = null;
				this.partSuffix = null;
				this.plantCode5Digit = null;
				this.plantCode4Digit = null;
				this.isValidGsdbCode = null;
				this.isSubmit = false;
				this.ngOnInit();
				setTimeout(() => {
					this.messageService.add({
						life: 300000,
						severity: 'success',
						summary: 'Success',
						detail: 'Partial Manual RFI/RFQ creation Reset.'
					});
				}, 30);
			},
			reject: () => {
				//do nothing
			}
		});
	}

	validateCheck(isCopied) {
		if (this.adsRequest.costEstimator === '') {
			this.isCostEstimator = false;
			this.isValidCostEstimator = false;
			return;
		}
		this.costEstimatorMap['buddyBuyer'] = this.adsRequest.costEstimator;
		this.partialRfiRequestService
			.validateCostEstimator(this.costEstimatorMap)
			.subscribe((response) => {
				if (response.status === 'Success') {
					this.isCostEstimator = true;
					this.validationMessage = response.validationMessage;
					if (response.validationMessage === 'CDSID is invalid') {
						this.isValidCostEstimator = true;
						if (isCopied) {
							this.isCopiedCostEstimator = false;
						}
					} else {
						this.isValidCostEstimator = false;
						if (isCopied) {
							this.isCopiedCostEstimator = false;
						}
					}
				} else {
					this.isCostEstimator = false;
					this.isValidCostEstimator = false;
					if (isCopied) {
						this.isCopiedCostEstimator = true;
					}
				}
			});
	}

	isSecondaryEngineerValid() {
		if (
			!this.adsRequest.secondaryEngineer ||
			this.adsRequest.secondaryEngineer.length === 0
		) {
			this.isValidSecondaryEngineer = true;
			return;
		}
	}

	validateSecondaryEngineer(copy) {
		if (!this.adsRequest.secondaryEngineer) {
			this.isValidSecondaryEngineer = true;
			return;
		}
		this.costEstimatorMap['buddyBuyer'] = this.adsRequest.secondaryEngineer;
		this.manualRfiRequestService
			.validateEngineer(this.costEstimatorMap)
			.subscribe((response) => {
				if (response.status === 'Success') {
					this.isValidSecondaryEngineer = true;
					if (copy) {
						this.isDRCopied = true;
					}
					this.validationSecondaryEngineerMessage =
						response.validationMessage;
					if (response.validationMessage === 'CDSID is invalid') {
						this.isValidSecondaryEngineer = false;
						if (copy) {
							this.isDRCopied = false;
						}
					} else {
						this.isValidSecondaryEngineer = false;
						if (copy) {
							this.isDRCopied = false;
						}
					}
				} else {
					this.isValidSecondaryEngineer = true;
					if (copy) {
						this.isDRCopied = true;
					}
				}
			});
	}

	validateBuyer() {
		this.costEstimatorMap['buddyBuyer'] = this.adsRequest.buyerCode?.trim();
		this.partialRfiRequestService
			.validateBuyer(this.costEstimatorMap)
			.subscribe((response) => {
				if (response.status === 'Success') {
					this.isBuyer = true;
					this.validationMessageBuyer = response.validationMessage;
					if (response.validationMessage === 'CDSID is invalid') {
						this.isValidBuyer = true;
					} else {
						this.isValidBuyer = false;
					}
				} else {
					this.isBuyer = false;
					this.isValidBuyer = false;
				}
			});
	}

	validateGsdbCode() {
		this.costEstimatorMap['buddyBuyer'] = this.adsRequest.siteCode?.trim();
		this.partialRfiRequestService
			.validateGsdbCode(this.costEstimatorMap)
			.subscribe((response) => {
				this.isValidGsdbCode = true;
				if (!response) {
					this.isValidGsdbCode = false;
				}
			});
	}

	checkPsaBuyer() {
		if (this.psaBuyer && this.psaBuyer !== this.adsRequest.buyerCode) {
			this.isPsaBuyer = true;
		} else {
			this.isPsaBuyer = false;
		}
	}

	private retriveRouteParameter() {
		let userId = sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (this.isAdminRole(apsRoles)) {
			userId = 'eDCM_IT_ADMIN';
		}
		console.log(userId);

		this.activatedRoute.paramMap.subscribe((params) => {
			this.id = params.get('id');
			this.partialRfiRequestService.findAll().subscribe((response) => {
				this.cmfrDCRList = [];
				this.cmfrDCRMap = response;
				// eslint-disable-next-line guard-for-in
				for (const cmfDCR in response) {
					this.cmfrDCRList.push({label: cmfDCR, value: cmfDCR});
				}

				this.retriveAllCosts(null);

				if (this.id && this.id !== 'null') {
					this.partialRfiDraftService
						.findById(this.id)
						// eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
						.subscribe((response) => {
							this.cmfrDCR = {
								label: response.requestNo.trim(),
								value: response.requestNo.trim()
							};
							this.onCMFDCRSelect(response);
							//this.plantCode = {plat5DigitCode: response.plant, plantCode4Digit: response.fourDigitPlantNo};
							this.loadPartialRFIDraft(response);
						});
				}
			});

			this.psaList = [];
		});
	}

	private retriveAllCosts(rfiId) {
		this.rfiCostRequestService
			.findByRfiHeaderId(rfiId, null)
			.subscribe((responseCosts) => {
				const rfiCost = responseCosts;

				this.materialCosts = rfiCost.filter(
					(res) => res.rfiType === 'Material'
				);
				this.commercialCosts = rfiCost.filter(
					(res) => res.rfiType === 'Commercial'
				);

				this.updateTotalCost(true);
			});
	}

	private retrivePDSystemData() {
		this.partialRfiRequestService
			.findPDSystemData(
				this.cmfrDCR.label,
				this.partPrefix,
				this.partBase,
				this.partSuffix,
				this.plantCode5Digit,
				this.plantCode4Digit === 'N/A' ? null : this.plantCode4Digit
			)
			.subscribe((response) => {
				this.pdSystemData = response;

				const isTimeExpired = this.pdSystemData.timeExpired;
				if (isTimeExpired) {
					this.confirmationService.confirm({
						message:
							'The 24-hour window to create an RFI/RFQ using the copy CMF/DCR function has expired. ' +
							'Please contact your supplier directly to obtain a cost estimate.',
						header: 'Confirmation',
						rejectVisible: false,
						acceptLabel: 'OK',
						icon: 'pi pi-exclamation-triangle',
						accept: () => {
							if (!this.id) {
								this.pdSystemData = {};
								this.rfiList = [];
								this.partNumberList = [];
								this.plantCodeList = [];
								this.adsRequest = {};
								this.partNumber = null;
								this.plantCode = null;
								this.plantCode5Digit = null;
								this.plantCode4Digit = null;
								this.partPrefix = null;
								this.partBase = null;
								this.partSuffix = null;
								this.cmfrDCR = null;
							}
						}
					});
				}
			});
	}

	private checkPartialRFIDraftAlreadyExist() {
		if (this.id === null || this.id === '' || this.id === 'null') {
			const filteredRoles = [
				'JV Engineer',
				'JV Buyer',
				'Buyer',
				'PD_Engineer'
			];
			let userId = sessionStorage.getItem('userId');
			const currentUser = userId;
			const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
			if (apsRoles && this.isAdminRole(apsRoles)) {
				userId = 'eDCM_IT_ADMIN';
			}

			if (apsRoles && apsRoles.includes('EDCM_VIEW_ONLY')) {
				userId = 'EDCM_VIEW_ONLY';
			}

			let rfiRequests = [];
			this.partialRfiDraftService
				.findAll(false, userId)
				.subscribe((result) => {
					if (
						filteredRoles.find((role) => role.includes(apsRoles[0]))
					) {
						rfiRequests = result.filter(
							(req) => req.createdBy === currentUser
						);
					} else {
						rfiRequests = result;
					}

					if (
						rfiRequests.find(
							(req) =>
								req.requestNo.trim() === this.cmfrDCR.label &&
								req.partNumber.trim() ===
									this.partNumber.label &&
								req.plant.trim() === this.plantCode5Digit
						)
					) {
						this.confirmationService.confirm({
							message:
								'Please use previously created Draft version.',
							header: 'Confirmation',
							rejectVisible: false,
							acceptLabel: 'OK',
							icon: 'pi pi-exclamation-triangle',
							accept: () =>
								(this.isDuplicateDraftPartialRFI = true)
						});
					}
				});
		}
	}

	private updateTotalCost(initialLoad) {
		let subMatTotPrevRFI = 0;
		let subMatTotCurRFI = 0;

		this.totPrevRFI = 0;
		this.totCurvRFI = 0;
		for (const result of this.materialCosts) {
			if (!result.isTotal) {
				subMatTotPrevRFI += +result.prevRFI;
				subMatTotCurRFI += +result.curRFI;
			}

			if (result.isTotal) {
				result.prevRFI = this.get4Decimal(subMatTotPrevRFI);
				result.curRFI = this.get4Decimal(subMatTotCurRFI);
			}
		}

		if (initialLoad) {
			this.materialCosts.push({
				costBucket: 'Piece Price Subtotal',
				prevRFI: subMatTotPrevRFI,
				curRFI: subMatTotCurRFI,
				isTotal: true,
				lookupId: -1
			});
		}

		let subComTotPrevRFI = 0;
		let subComTotCurRFI = 0;

		for (const result of this.commercialCosts) {
			if (!result.isTotal) {
				subComTotPrevRFI += +result.prevRFI;
				subComTotCurRFI += +result.curRFI;
			}

			if (result.isTotal) {
				result.prevRFI = this.get4Decimal(subComTotPrevRFI);
				result.curRFI = this.get4Decimal(subComTotCurRFI);
			}
		}

		if (initialLoad) {
			this.commercialCosts.push({
				costBucket: 'Commercial Adjustment Total',
				prevRFI: subComTotPrevRFI,
				curRFI: subComTotCurRFI,
				isTotal: true,
				lookupId: -1
			});
		}

		this.totPrevRFI = subMatTotPrevRFI + subComTotPrevRFI;
		this.totCurvRFI = subMatTotCurRFI + subComTotCurRFI;
	}

	private checkMandatoryFields(adsData) {
		const isAdsDataPresent =
			!adsData.psaNumber ||
			!adsData.supplierUniqueName ||
			!adsData.manufacturingSiteCode ||
			!adsData.buyerCdsId ||
			!adsData.program ||
			!adsData.currency;
		const isParanetDataPresent =
			!this.cmfrDCR ||
			!this.cmfrDCR.label ||
			!this.partNumber ||
			!this.partNumber.label ||
			!this.plantCode5Digit ||
			!this.plantCode4Digit;

		return !isAdsDataPresent && !isParanetDataPresent;
	}

	private getADSData() {
		return {
			program: this.adsRequest.leadPrg,
			psaNumber: parseInt(this.adsRequest.psa, 10),
			currency: this.adsRequest.resCur,
			plant: this.plantCode5Digit,
			totalRawMaterial: this.filterMaterialCost(
				'Total Raw Material Cost'
			),
			boughtOutContent: this.filterMaterialCost(
				'Total Purchased Parts / Bought-Out Content'
			),
			totalLabor: this.filterMaterialCost('Total Labor'),
			totalBurden: this.filterMaterialCost('Total Burden'),
			endItemScrap: this.filterMaterialCost('End Item Scrap'),
			sga: this.filterMaterialCost('SG&A'),
			profit: this.filterMaterialCost('Profit'),
			boughtOutMarkups: this.filterMaterialCost(
				'Total Purchased Parts / Bought-Out Mark-Ups'
			),
			grossPrice: parseFloat(
				this.get4Decimal(
					this.filterMaterialCost('Piece Price Subtotal')
				)
			),
			edt: this.filterCommercialCost('ED&T paid in Piece Price'),
			packaging: this.filterCommercialCost('Packaging'),
			ilvs: this.filterCommercialCost('ILVS'),
			freight: this.filterCommercialCost('Freight Value'),
			duty: this.filterCommercialCost('Duty'),
			other: this.filterCommercialCost('Other (Miscellaneous)'),
			netPrice: parseFloat(
				this.get4Decimal(
					this.filterCommercialCost('Commercial Adjustment Total')
				)
			),
			manufacturingSiteCode: this.adsRequest.siteCode,
			materialNumber:
				this.partPrefix + '-' + this.partBase + '-' + this.partSuffix,
			supplierUniqueName: this.adsRequest.supplier,
			edtToolingTotal: this.adsRequest.toolingCostTarget,
			buyerCdsId: this.adsRequest.buyerCode,
			commodityCode: this.adsRequest.purCommCode,
			commodityName: this.adsRequest.purCommDesc,
			//programReceived:,
			costEstimator: this.adsRequest.costEstimator,
			supplierEngineerName: this.adsRequest.supplierEnggEmail,
			quoteType: 'I',
			prevSuffix: this.adsRequest.prevSuffix,
			isPsaBuyer: this.isPsaBuyer,
			secondaryEngineer: this.adsRequest.secondaryEngineer
		};
	}

	private getPDSystemData() {
		return {
			program: this.pdSystemData.leadPrg,
			partPrefix: this.pdSystemData.prefix,
			partBase: this.pdSystemData.base,
			partSuffix: this.pdSystemData.suffix,
			prevPartSuffix: this.pdSystemData.suffix,
			partName: this.pdSystemData.partName,
			requestDescription: this.pdSystemData.cmfDCRChangeDesc,
			drCdsId: this.pdSystemData.drCDSID,
			plantNo: this.pdSystemData.mplPlatCode,
			postFdj: this.pdSystemData.postFDJ,
			engineeringDetails: this.pdSystemData.cmfDCRLink,
			materialCostStatus: Number(this.pdSystemData.piecePriceStatus),
			toolingCostStatus: Number(this.pdSystemData.prodToolStatus),
			materialCostEngineeringEstimate: Number(
				this.pdSystemData.piecePriceEstimate
			),
			toolingCostEngineeringEstimate: Number(
				this.pdSystemData.prodToolEstim
			),
			partCostTarget: Number(this.pdSystemData.initialPiecePrice),
			toolingCostTarget: Number(this.pdSystemData.initialProdToolTar),
			materialCostStatusCurrency: this.pdSystemData.piecePriceStatusCurr,
			toolingCostStatusCurrency: this.pdSystemData.prodToolStatusCurr,
			//commercialAdjustmentCostStatusCurrency: this.pdSystemData.prodToolStatusCurr,
			materialCostEngineeringEstimateCurrency:
				this.pdSystemData.piecePriceEstimateCurr,
			toolingCostEngineeringEstimateCurrency:
				this.pdSystemData.prodToolEstimCurr,
			partCostTargetCurrency: this.pdSystemData.initialPiecePriceCurr,
			toolingCostTargetCurrency: this.pdSystemData.initialProdToolTarCurr,
			fourDigitPlantNo: this.pdSystemData.fedeBomCode,
			psaNumbers: this.pdSystemData.psa.toString(),
			requestTitle: this.pdSystemData.cmfDCRTitle,
			requestNo: this.pdSystemData.cmfDCR,
			secondaryEngineer: this.adsRequest.secondaryEngineer,
			userAction: 'ADD',
			role: JSON.parse(sessionStorage.getItem('apsRoles'))[0],
			createdBy: sessionStorage.getItem('userId'),
			updateIntCstDetailsBy: sessionStorage.getItem('userId'),
			crApproved: this.pdSystemData.isCrApproved,
			isRfq: this.pdSystemData.isRfq,
			proposedCostMaturity: this.pdSystemData.proposedCostMaturity
		};
	}

	private savePartialRFI() {
		this.validationMessageBuyer = 'Buyer CD-SID is required';
		const adsData = this.getADSData();
		const pdSystemData = this.getPDSystemData();

		if (!this.isMandatoryFields()) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Waning',
					detail: 'Mandatory Fields are required for submission.'
				});
			}, 30);
			return;
		}

		this.partialRfiRequestService
			.isErrorLogExpired(pdSystemData)
			.subscribe({
				next: (response) => {
					if (response) {
						setTimeout(() => {
							this.messageService.add({
								life: 300000,
								severity: 'warn',
								summary: 'Waning',
								detail: 'PdSystem data has been expired in E3PBOM table.'
							});
						}, 30);
					} else {
						this.partialRfiRequestService
							.isPdSystemDataExisting(pdSystemData)
							// eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
							.subscribe((response) => {
								if (response) {
									setTimeout(() => {
										this.messageService.add({
											life: 300000,
											severity: 'warn',
											summary: 'Waning',
											detail: 'Given PdSystem data combinaton already existed in E3PBOM table.'
										});
									}, 30);
								} else {
									if (this.id) {
										this.partialRfiDraftService
											.deleteById(this.id)
											// eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
											.subscribe((response) => {
												console.log(response);
											});
									}

									this.partialRfiRequestService
										.savePdSystemData(pdSystemData)
										// eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
										.subscribe((response) => {
											const cmfDCR =
												this.pdSystemData.cmfDCR;
											this.partialRfiRequestService
												.saveADSData(adsData)
												// eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
												.subscribe((response) => {
													this.partialRfiRequestService
														.isADSDataSaved(adsData)
														.subscribe(
															// eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
															(response) => {
																if (!response) {
																	setTimeout(
																		() => {
																			/*this.messageService.add(
																				{
																					life: 300000,
																					severity:
																						'warn',
																					summary:
																						'Warning',
																					detail: 'Invalid Data'
																				}
																			);*/
																		},
																		30
																	);
																}
															}
														);
													console.log(response);
												});
											this.partialRfiRequestService
												.updateInetgrationErrorLog(
													cmfDCR,
													this.partPrefix,
													this.partBase,
													this.partSuffix,
													this.plantCode5Digit,
													this.plantCode4Digit ===
														'N/A'
														? null
														: this.plantCode4Digit
												)
												// eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
												.subscribe((response) => {
													console.log(response);
													this.cmfrDCR = null;
													this.partNumber = null;
													this.plantCode = null;
													if (this.isPsaBuyer) {
														this.partialRfiRequestService
															.validatePsaBuyer(
																this.getPDSystemData()
															)
															.subscribe(
																// eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
																(response) => {
																	if (
																		response
																	) {
																		const psaData =
																			{
																				psaNumber:
																					parseInt(
																						this
																							.adsRequest
																							.psa,
																						10
																					),
																				buyerCdsId:
																					this
																						.adsRequest
																						.buyerCode,
																				oldBuyerCdsId:
																					this
																						.psaBuyer
																			};
																		this.partialRfiRequestService
																			.savePsaBuyerData(
																				psaData
																			)
																			.subscribe(
																				(
																					// eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
																					response
																				) => {
																					this.psaBuyer =
																						this.adsRequest.buyerCode;
																					this.isPsaBuyer =
																						false;
																					setTimeout(
																						() => {
																							this.messageService.add(
																								{
																									life: 300000,
																									severity:
																										'success',
																									summary:
																										'Success',
																									detail: 'Your Partial RFI/RFQ request has been submitted and you will get an email with the RFI number. Please wait for 15 minutes for the RFI to be created'
																								}
																							);
																						},
																						30
																					);
																					console.log(
																						response
																					);
																				}
																			);
																	} else {
																		setTimeout(
																			() => {
																				this.messageService.add(
																					{
																						life: 300000,
																						severity:
																							'warn',
																						summary:
																							'Warning',
																						detail: 'Invalid Data'
																					}
																				);
																			},
																			30
																		);
																	}
																}
															);
													} else {
														setTimeout(() => {
															this.messageService.add(
																{
																	life: 300000,
																	severity:
																		'success',
																	summary:
																		'Success',
																	detail: 'Your Partial RFI/RFQ request has been submitted and you will get an email with the RFI number. Please wait for 15 minutes for the RFI to be created'
																}
															);
														}, 30);
													}
												});
											console.log(response);
										});
								}
								console.log(response);
							});
					}
				},
				error: (error) => {
					// error
					console.error(error);
					setTimeout(() => {
						this.messageService.add({
							life: 300000,
							severity: 'warn',
							summary: 'Warning',
							detail: 'Invalid Data'
						});
					}, 30);
				}
			});
	}

	cancelInternalCostDetails() {
		this.toggleInteranlCostDetailsEdit(false);
		this.retrivePDSystemData();
	}

	canInteranlCostDetailsEdit(flag) {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles && this.isAdminRole(apsRoles) && flag;
	}
	toggleInteranlCostDetailsEdit(value) {
		this.interanlCostDetailsEdit = value;
	}
	isEDCMViewOnlyRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('EDCM_VIEW_ONLY');
	}
	getInterCostDetailsUpdateString() {
		const updateDate = moment(
			this.pdSystemData.updateIntCstDetailsDate
		).format('MM/DD/YYYY HH:MM');
		return (
			'Updated By' +
			'-' +
			this.pdSystemData.updateIntCstDetailsBy +
			'--' +
			this.pdSystemData.role +
			'--' +
			updateDate
		);
	}
	saveInternalCotDetails() {
		this.toggleInteranlCostDetailsEdit(false);
		const pdSystemData = this.getPDSystemData();
		this.pdSystemData.role = JSON.parse(
			sessionStorage.getItem('apsRoles')
		)[0];
		this.pdSystemData.createdBy = sessionStorage.getItem('userId');
		this.pdSystemData.updateIntCstDetailsBy =
			sessionStorage.getItem('userId');
		this.pdSystemData.partCostTarget = Number(
			this.pdSystemData.partCostTarget
		);
		this.pdSystemData.pdMatCostEstimate = Number(
			this.pdSystemData.pdMatCostEstimate
		);
		this.pdSystemData.e3pMatCostEstimate = Number(
			this.pdSystemData.e3pMatCostEstimate
		);
		this.pdSystemData.toolingCostTarget = Number(
			this.pdSystemData.toolingCostTarget
		);
		this.pdSystemData.pdToolingEstimate = Number(
			this.pdSystemData.pdToolingEstimate
		);
		this.pdSystemData.e3pToolingEstimate = Number(
			this.pdSystemData.e3pToolingEstimate
		);
		this.pdSystemData.rfiPrevCost = Number(this.pdSystemData.rfiPrevCost);
		this.pdSystemData.updateIntCstDetailsDate = moment();
		this.pdSystemData.rfiCost = Number(this.pdSystemData.rfiCost);
		this.partialRfiRequestService
			.saveRFIInternalCostDetailsPartial(pdSystemData)
			.subscribe(() => {
				setTimeout(() => {
					this.messageService.add({
						life: 300000,
						severity: 'success',
						summary: 'RFI/RFQ Internal Cost details has been saved.'
					});
				}, 30);
			});
	}
}
