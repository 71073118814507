/**
 * @author DCHIRUM1
 */
import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {ApsService} from '../services/aps.service';
import {UrlConsumerService} from '../oauth/url-consumer.service';
import {Router} from '@angular/router';
import {BannerService} from '../services/rfi/banner.service';
import {Subscription} from 'rxjs';
import {BannerDetails} from '../models/BannerDetails';

/** Angular component that utilizes the PrimeNG p-menubar component
 * to display the navigation menu for the application
 */
@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, OnDestroy {
	/** Array of PrimeNG MenuItems that are used to populate the p-menubar */
	navMenuItems: MenuItem[];
	/** whether or not the sidebar is open or not */
	sidebarVisible: boolean;
	navbarVisible = true;
	noaccess = true;
	apsRoles: string;
	private bannerSubscription: Subscription;
	private notificationSubscription: Subscription;
	private bannerDetails: BannerDetails;

	/** Create a nav component and populate with necessary MenuItems (static data) */
	constructor(
		private bannerService: BannerService,
		private apsService: ApsService,
		private urlConsumerService: UrlConsumerService,
		private router: Router
	) {
		this.navMenuItems = [
			{
				label: 'Home',
				routerLink: '/home',
				visible: false,
				command: this.hideSidebar,
				icon: 'pi pi-home'
			},
			{
				label: 'Workspace',
				routerLink:
					'/rfi-request/buyer/all/all/BuyerSelectRFI/false/false/RFQ',
				icon: 'pi pi-desktop',
				visible: false,
				command: this.hideSidebar,
				items: [
					{
						label: 'Manage RFIs/RFQs',
						routerLink:
							'/rfi-request/buyer/all/all/BuyerSelectRFI/false/false/RFQ',
						icon: 'pi pi-cog'
					},
					{
						label: 'Manage Threshold',
						routerLink: '/threshold',
						icon: 'pi pi-calculator'
					},
					{
						label: 'Create Partial RFI/RFQs',
						routerLink: '/partial-draft-rfi-request',
						icon: 'pi pi-book'
					},
					/*{
							label: 'Manage Material Groups',
							routerLink: '/comm2-partbase',
							icon: 'pi pi-cog'
						},*/
					{
						label: 'Update Buyer',
						routerLink: '/buddy-buyer',
						icon: 'pi pi-users'
					},
					{
						label: 'Update Supplier',
						routerLink: '/supplier-contact',
						icon: 'pi pi-users'
					},
					{
						label: 'Update Cost-Estimator',
						routerLink: '/cost-estimator',
						icon: 'pi pi-users'
					},
					{
						label: 'Update Second D&R',
						routerLink: '/second-dandr',
						icon: 'pi pi-users'
					},
					{
						label: 'Update Secondary Supplier',
						routerLink: '/supplier-engineer',
						icon: 'pi pi-users'
					}
				]
			},
			{
				label: 'Workspace',
				routerLink:
					'/rfi-request/buyer/all/all/BuyerSelectRFI/false/false/RFQ',
				icon: 'pi pi-desktop',
				visible: false,
				command: this.hideSidebar,
				items: [
					{
						label: 'Manage RFIs/RFQs',
						routerLink:
							'/rfi-request/buyer/all/all/BuyerSelectRFI/true/false/RFQ',
						icon: 'pi pi-cog'
					},

					{
						label: 'Create Partial RFI/RFQ',
						routerLink: '/partial-draft-rfi-request',
						icon: 'pi pi-book'
					},
					/*{
							label: 'Manage Material Groups',
							routerLink: '/comm2-partbase',
							icon: 'pi pi-cog'
						},*/
					{
						label: 'Update Supplier',
						routerLink: '/supplier-contact',
						icon: 'pi pi-users'
					},
					{
						label: 'Update Cost-Estimator',
						routerLink: '/cost-estimator',
						icon: 'pi pi-users'
					},
					{
						label: 'Update Second D&R',
						routerLink: '/second-dandr',
						icon: 'pi pi-users'
					},
					{
						label: 'Update Secondary Supplier',
						routerLink: '/supplier-engineer',
						icon: 'pi pi-users'
					}
				]
			},
			{
				label: 'Workspace',
				routerLink:
					'/rfi-request/buyer/all/all/BuyerSelectRFI/true/false/RFQ',
				icon: 'pi pi-desktop',
				visible: false,
				command: this.hideSidebar,
				items: [
					{
						label: 'Manage RFIs/RFQs',
						routerLink:
							'/rfi-request/buyer/all/all/BuyerSelectRFI/true/false/RFQ',
						icon: 'pi pi-cog'
					},
					{
						label: 'Update Cost-Estimator',
						routerLink: '/cost-estimator',
						icon: 'pi pi-users'
					}
				]
			},

			{
				label: 'Manual RFI',
				routerLink:
					'/manual-rfi-request/buyer/all/all/BuyerSelectRFI/false/false',
				command: this.hideSidebar,
				visible: false,
				icon: 'pi pi-file-import'
			},
			{
				label: 'Supplier',
				routerLink:
					'/rfi-request/supplier/all/all/SupplierSelectRFI/false/false/RFQ',
				icon: 'pi pi-user-edit',
				visible: false,
				command: this.hideSidebar,
				items: [
					{
						label: 'Manage RFIs/RFQs',
						routerLink:
							'/rfi-request/supplier/all/all/SupplierSelectRFI/false/false/RFQ',
						icon: 'pi pi-cog'
					},
					{
						label: 'Update Supplier',
						routerLink: '/supplier-contact',
						icon: 'pi pi-users'
					},
					{
						label: 'Manual RFI',
						routerLink:
							'/manual-rfi-request/supplier/all/all/SupplierSelectRFI/false/false',
						icon: 'pi pi-file-import'
					},
					{
						label: 'Update Secondary Supplier',
						routerLink: '/supplier-engineer',
						icon: 'pi pi-users'
					}
				]
			},
			{
				label: 'Manage RFIs/RFQs',
				routerLink:
					'/rfi-request/buyer/all/all/BuyerSelectRFI/true/true/RFQ',
				icon: 'pi pi-cog',
				visible: false,
				command: this.hideSidebar
			},
			{
				label: 'Manual RFI',
				routerLink:
					'/manual-rfi-request/buyer/all/all/BuyerSelectRFI/true/false',
				command: this.hideSidebar,
				visible: false,
				icon: 'pi pi-file-import'
			},
			{
				label: 'Admin',
				visible: false,
				id: 'admin',
				command: this.hideSidebar,
				items: [
					{
						label: 'Banner Message',
						routerLink: '/app-banner',
						icon: 'pi pi-volume-up'
					},
					{
						label: 'Upload Help Document',
						routerLink: '/help-document',
						icon: 'pi pi-list'
					}
				]
			},
			{
				label: 'Help',
				visible: true,
				routerLink: '/help-document-download',
				icon: 'pi pi-question'
			}
		];
	}

	/** toggles visibility of sidebar to true */
	showSidebar = () => {
		this.sidebarVisible = true;
	};

	/** toggles visibility of sidebar to false */
	hideSidebar = () => {
		this.sidebarVisible = false;
	};

	/** Listener to toggle sidebar to hidden when it is not displayed */
	@HostListener('window:resize', []) onResize() {
		if (this.sidebarVisible === true) {
			const sideBar = document.getElementById('app-sidebar');
			if (window.getComputedStyle(sideBar).display === 'none') {
				this.sidebarVisible = false;
			}
		}
	}

	ngOnInit() {
		/*this.bannerSubscription = this.bannerService.getBannerMessage().subscribe((bannerDetails: BannerDetails) => {
			if (bannerDetails) {
				this.bannerService.bannerMessageSubject.next(bannerDetails.bannerMessage);
			}
		});*/
		this.buildMenuBasedOnApsRoles();
	}

	buildMenuBasedOnApsRoles() {
		if (sessionStorage.getItem('apsRoles') == null) {
			this.urlConsumerService.apsUserRole.subscribe((result) => {
				console.log(result);
				this.apsRoles = result;
				this.rearrangeMenus(this.apsRoles);
				location.reload();
			});
		} else {
			const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
			this.rearrangeMenus(apsRoles);
		}
	}

	rearrangeMenus(apsRoles) {
		const homeIndex = 0;
		const buyerIndex = 1;
		const pdEngineerIndex = 2;
		const costEstimator = 3;
		const manualrfiIndex = 4;
		const supplierIndex = 5;
		const searchRfiIndex = 6;
		const manualIndex = 7;
		const adminIndex = 8;

		console.log('apsRoles : ' + apsRoles);
		if (apsRoles.length === 0 || apsRoles === '[]') {
			this.navbarVisible = true;
			console.log('No access ');
			this.router.navigate(['/no-access']);
		}

		this.navMenuItems[homeIndex].visible =
			apsRoles.includes('Buyer') ||
			apsRoles.includes('EDCM_UPDATE') ||
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('eDCM_Super_Buyer') ||
			apsRoles.includes('PD_Engineer') ||
			apsRoles.includes('CostEstimator') ||
			apsRoles.includes('JV Buyer') ||
			apsRoles.includes('JV Engineer') ||
			apsRoles.includes('JV Costestimator') ||
			apsRoles.includes('EDCM_VIEW_ONLY');
		this.navMenuItems[buyerIndex].visible =
			apsRoles.includes('Buyer') ||
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('eDCM_Super_Buyer') ||
			apsRoles.includes('JV Buyer');
		this.navMenuItems[pdEngineerIndex].visible =
			apsRoles.includes('PD_Engineer') ||
			apsRoles.includes('JV Engineer');
		this.navMenuItems[costEstimator].visible =
			apsRoles.includes('CostEstimator') ||
			apsRoles.includes('JV Costestimator');
		this.navMenuItems[manualrfiIndex].visible =
			apsRoles.includes('Buyer') ||
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('eDCM_Super_Buyer') ||
			apsRoles.includes('PD_Engineer') ||
			apsRoles.includes('CostEstimator') ||
			apsRoles.includes('JV Buyer') ||
			apsRoles.includes('JV Engineer') ||
			apsRoles.includes('JV Costestimator');
		this.navMenuItems[supplierIndex].visible =
			apsRoles.includes('EDCM_UPDATE') ||
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('eDCM_Super_Buyer');
		this.navMenuItems[searchRfiIndex].visible =
			apsRoles.includes('EDCM_VIEW_ONLY');
		this.navMenuItems[manualIndex].visible =
			apsRoles.includes('EDCM_VIEW_ONLY');
		this.navMenuItems[adminIndex].visible =
			apsRoles.includes('eDCM_IT_ADMIN');
		//this.navMenuItems[manualrfiIndex].visible = apsRoles.includes('Buyer') ||
		// apsRoles.includes('EDCM_UPDATE') || apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('eDCM_Super_Buyer')
		// || apsRoles.includes('PD_Engineer');
		this.bannerService.getBannerMessage().subscribe((bannerDetails) => {
			if (bannerDetails) {
				this.bannerDetails = bannerDetails;
				this.bannerService.bannerMessageSubject.next(
					this.bannerDetails.bannerMessage
				);
			}
		});
	}

	ngOnDestroy() {
		if (this.bannerSubscription) {
			this.bannerSubscription.unsubscribe();
		}
		if (this.notificationSubscription) {
			this.notificationSubscription.unsubscribe();
		}
		if (this.urlConsumerService.apsUserRole) {
			this.urlConsumerService.apsUserRole.unsubscribe();
		}
	}
}
